<template>
<div class="info">
  <div class="about">
    <div class="portrait">
      image
    </div>
    <div class="contact">
      contact email service
    </div>
    <div class="description">
      description
    </div>
  </div>
  <div class="experience">
    year - project
  </div>
</div>
</template>

<script>

export default {
  name: 'CV',
}
</script>

<style scoped>
.info {
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}
.about {
  flex-grow: 1;
  text-align: left;
}
.experience {
  flex-grow: 3;
  text-align: left;
}

</style>
