<template>
  <div class="project">
    <PdfLayouts :images=images />
  </div>
  
</template>
  
<script>
import ProjectsData from  '@/data/projectsDatabase'
import PdfLayouts from '@/components/PdfLayouts.vue';


export default {
  name: 'ProjectView',
  components: {
    PdfLayouts
},
  created() {
    window.scrollTo(0,0);
    const projectId = this.$route.params.id;
    this.project = ProjectsData.find( (project) => project.id == projectId);
    this.images = this.project.pdfImages;
  },
  data() {
    return {
      project: null,
      description: '',
      images: [],
    }
  },
}
</script>
<style scoped>
  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: x-large;  
  }
  .date {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .project-data {
    display: flex;
    justify-content: space-between;
  }
  .gallery {
    flex: 21;
    padding-left: 90px;
    padding-right: 90px;
  }
  .information {
    white-space: pre-line;
    color: rgb(118, 118, 118);
    text-align: left;
    flex: 21;
    padding-left: 20px;
  }
  .description {
    width: 80%;
    font-size: medium;
    line-height: 1.8;
  }

</style>
  