<template>
  <div class="project-grid-item">
    <div class="project-content">
      <!-- Your project content goes here -->
      <div class="image-container">
        <img v-lazy="project.key_image.src" alt="Image" class="image" />
      </div>
      
      <!-- key_image -->
      <div class="overlay">
        <div class="title">{{ project.title }}</div>
        <div class="date">{{ projectYear }}</div>
      </div>
    </div>
    <!-- <div class="image" v-for="image in project.images" :key="image">{{image.src}}</div> -->
  </div>
</template>

<script>
export default {
  name: 'ProjectGridItem',
  props: {
    project: Object
  },
  computed: {
    projectYear() {
      return (new Date(this.project.date)).getFullYear();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.project-grid-item {
  position: relative;
  padding-top: 75%; /* 4:3 aspect ratio (you can adjust this value) */
}
.project-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Additional styling for the content within the grid item */

  transition: transform 0.3s;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark background with transparency */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.project-grid-item:hover .overlay {
  opacity: 1;
}
.date {
  color: white;
}
.overlay .title {
  color: #fff; /* Text color */
  text-transform: uppercase;
  font-size: 1.5rem; /* Adjust the text size */
  text-align: center;
  font-weight: bold;
  padding: 5px;
}
.image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

</style>
