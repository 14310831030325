import { createRouter, createWebHistory } from 'vue-router'
import { createApp } from 'vue'
import App from './App.vue'
import Projects from './views/Projects.vue'
import Project from './views/Project.vue'
import CV from './views/CV.vue'
import About from './views/About.vue'
import VueLazyload from 'vue-lazyload';


const routes = [
  { path: '/', component: Projects },
  { path: '/cv', component: CV },
  { path: '/about', component: About },
  { path: '/project/:id', component: Project }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  routes
})

const app = createApp(App)
app.use(router)
app.use(VueLazyload)
app.mount('#app')

