
    const projectsDatabase = [
  {
    "id": 1,
    "title": "Alfi",
    "description": "assets/projects/alfi/description.txt",
    "date": "2019-02-10",
    "key_image": {
      "src": "assets/projects/alfi/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/alfi/portfolio_01.jpg",
      "assets/projects/alfi/portfolio_02.jpg"
    ]
  },
  {
    "id": 2,
    "title": "Assitech",
    "description": "assets/projects/assitech/description.txt",
    "date": "2020-06-10",
    "key_image": {
      "src": "assets/projects/assitech/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/assitech/portfolio_01.jpg",
      "assets/projects/assitech/portfolio_02.jpg",
      "assets/projects/assitech/portfolio_03.jpg",
      "assets/projects/assitech/portfolio_04.jpg",
      "assets/projects/assitech/portfolio_05.jpg"
    ]
  },
  {
    "id": 3,
    "title": "Beocenter 9000",
    "description": "assets/projects/beocenter/description.txt",
    "date": "2023-08-10",
    "key_image": {
      "src": "assets/projects/beocenter/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/beocenter/portfolio_1.jpg",
      "assets/projects/beocenter/portfolio_2.jpg",
      "assets/projects/beocenter/portfolio_3.jpg",
      "assets/projects/beocenter/portfolio_4.jpg"
    ]
  },
  {
    "id": 4,
    "title": "Doppler Router",
    "description": "assets/projects/doppler/description.txt",
    "date": "2020-12-10",
    "key_image": {
      "src": "assets/projects/doppler/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/doppler/portfolio_01.jpg",
      "assets/projects/doppler/portfolio_02.jpg",
      "assets/projects/doppler/portfolio_03.jpg"
    ]
  },
  {
    "id": 5,
    "title": "Keramikk",
    "description": "assets/projects/keramikk/description.txt",
    "date": "2020-05-10",
    "key_image": {
      "src": "assets/projects/keramikk/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/keramikk/portfolio_01.jpg",
      "assets/projects/keramikk/portfolio_02.jpg",
      "assets/projects/keramikk/portfolio_03.jpg"
    ]
  },
  {
    "id": 6,
    "title": "Kiellands Kafe",
    "description": "assets/projects/kielland/description.txt",
    "date": "2023-07-20",
    "key_image": {
      "src": "assets/projects/kielland/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/kielland/portfolio_01.jpg",
      "assets/projects/kielland/portfolio_02.jpg",
      "assets/projects/kielland/portfolio_03.jpg",
      "assets/projects/kielland/portfolio_04.jpg"
    ]
  },
  {
    "id": 7,
    "title": "Knife",
    "description": "assets/projects/knifeholder/description.txt",
    "date": "2018-05-10",
    "key_image": {
      "src": "assets/projects/knifeholder/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/knifeholder/portfolio_01.jpg"
    ]
  },
  {
    "id": 8,
    "title": "Laft Lounge",
    "description": "assets/projects/laft/description.txt",
    "date": "2020-12-10",
    "key_image": {
      "src": "assets/projects/laft/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/laft/portfolio_01.jpg",
      "assets/projects/laft/portfolio_02.jpg",
      "assets/projects/laft/portfolio_03.jpg",
      "assets/projects/laft/portfolio_04.jpg",
      "assets/projects/laft/portfolio_05.jpg"
    ]
  },
  {
    "id": 9,
    "title": "Bauhaus 100",
    "description": "assets/projects/marionette/description.txt",
    "date": "2019-11-10",
    "key_image": {
      "src": "assets/projects/marionette/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/marionette/portfolio_01.jpg",
      "assets/projects/marionette/portfolio_02.jpg"
    ]
  },
  {
    "id": 10,
    "title": "Pencil Pop",
    "description": "assets/projects/pencil/description.txt",
    "date": "2020-05-10",
    "key_image": {
      "src": "assets/projects/pencil/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/pencil/portfolio_01.jpg",
      "assets/projects/pencil/portfolio_02.jpg",
      "assets/projects/pencil/portfolio_03.jpg"
    ]
  },
  {
    "id": 11,
    "title": "Straen No 70",
    "description": "assets/projects/straen no 70/description.txt",
    "date": "2022-12-10",
    "key_image": {
      "src": "assets/projects/straen no 70/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/straen no 70/portfolio_01.jpg",
      "assets/projects/straen no 70/portfolio_02.jpg",
      "assets/projects/straen no 70/portfolio_03.jpg",
      "assets/projects/straen no 70/portfolio_04.jpg",
      "assets/projects/straen no 70/portfolio_05.jpg",
      "assets/projects/straen no 70/portfolio_06.jpg"
    ]
  },
  {
    "id": 12,
    "title": "Temple Stool",
    "description": "assets/projects/temple/description.txt",
    "date": "2021-12-10",
    "key_image": {
      "src": "assets/projects/temple/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/temple/portfolio_01.jpg",
      "assets/projects/temple/portfolio_02.jpg",
      "assets/projects/temple/portfolio_03.jpg",
      "assets/projects/temple/portfolio_04.jpg"
    ]
  },
  {
    "id": 13,
    "title": "Urkraft",
    "description": "assets/projects/urkraft/description.txt",
    "date": "2022-02-10",
    "key_image": {
      "src": "assets/projects/urkraft/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/urkraft/portfolio_01.jpg",
      "assets/projects/urkraft/portfolio_02.jpg",
      "assets/projects/urkraft/portfolio_03.jpg"
    ]
  },
  {
    "id": 14,
    "title": "Vardatua",
    "description": "assets/projects/vardatua/description.txt",
    "date": "2022-05-10",
    "key_image": {
      "src": "assets/projects/vardatua/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/vardatua/portfolio_01.jpg",
      "assets/projects/vardatua/portfolio_02.jpg",
      "assets/projects/vardatua/portfolio_03.jpg",
      "assets/projects/vardatua/portfolio_04.jpg",
      "assets/projects/vardatua/portfolio_05.jpg",
      "assets/projects/vardatua/portfolio_06.jpg"
    ]
  },
  {
    "id": 15,
    "title": "Vesuv",
    "description": "assets/projects/vesuv/description.txt",
    "date": "2018-12-10",
    "key_image": {
      "src": "assets/projects/vesuv/key_image.webp"
    },
    "pdfImages": [
      "assets/projects/vesuv/portfolio_01.jpg",
      "assets/projects/vesuv/portfolio_02.jpg",
      "assets/projects/vesuv/portfolio_03.jpg",
      "assets/projects/vesuv/portfolio_04.jpg"
    ]
  }
];
    module.exports = projectsDatabase;
  