<template>
  <div class="PageTitle">Lorentz J. Houser</div>
  <div class="PageDescription">DESIGNER // ARCHITECT</div>
  <div class="projects">
    <ProjectGridItem :project="project" v-for="project in orderedProjects" :key="project.id" @click="navigateToProject(project.id)"/>
  </div>
</template>
  
<script>
import ProjectGridItem from '@/components/ProjectGridItem'
import ProjectsData from  '@/data/projectsDatabase'

export default {
  name: 'ProjectsOverview',
  components: {
    ProjectGridItem,
  },
  methods: {
    navigateToProject(id) {
      this.$router.push(`/project/${id}`); // Use this.$router to navigate
    },
  },
  created() {
    window.scrollTo(0,0);
  },
  computed: {
    orderedProjects() {
    return this.ProjectsData.slice().sort((project1, project2) => {
      return new Date(project2.date) - new Date(project1.date);
    });
  }
  },
  data() {
    return {
        ProjectsData
    };
  },
};
</script>
  <style> 
  .PageTitle {
    text-transform: uppercase;
    font-size: xx-large;
    font-weight: bold;
    padding-top: 100px;
  }
  .PageDescription {
    text-transform: uppercase;
    font-size: medium;
    padding-top: 20px;
    padding-bottom: 100px;
  }
    .projects {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2-column grid, full width */
        grid-gap: 5px; /* Adjust the gap between items */
    }

    @media (max-width: 768px) {
    .projects {
        grid-template-columns: 1fr; /* Single column on mobile */
    }
    }
  </style>
  