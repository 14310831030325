<template>
  <div class="imageContainer">
    <img v-for="image in images" :key='image' v-lazy="publicPath+image" alt="Image" class="image" />
  </div>
</template>
  
<script>
export default {
name: 'PdfLayouts',
props: {
  images : Array,
},
data() {
  return {
    publicPath: process.env.BASE_URL,
  }
}
};
</script>
  
<style scoped>

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px;
}
.image {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: contain;
}

</style>

