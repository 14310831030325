<template>
  <div class="navbar">
    <div class="navItem">
    <router-link class="navLink" to="/">projects</router-link>
    </div>
    <div class="navItem">/</div>
    <div class="navItem">
      <!-- <router-link class="navLink" to="/cv">cv</router-link> -->
      <router-link class="navLink" to="/about">about</router-link>
    </div>
    <!-- <div class="navItem">/</div>
    <div class="navItem">
      <router-link class="navLink" to="/cv">contact</router-link>
    </div> -->
  </div>
</template>
  
  <script>
  
  export default {
    name: 'NavBar',
    computed: {
      
      filteredRoutes() {
        // Filter out the current route
        return this.$router.options.routes.filter((route) => {
          return route.name !== this.$route.name;
        });
    },
  },
  }
  </script>
  
<style scoped>
.navbar {
  position: fixed; /* This makes the navbar stay fixed within the viewport */
  top: 0; /* Position at the top of the viewport */
  left: 0; /* Position at the left of the viewport */
  width: 100%; /* Full width of the viewport */
  z-index: 1000; /* A higher z-index to ensure it appears above other content */
  background-color: white;
  width: 100%;
  height: 25px;
}
.navItem {
   color: black;
   float: left;
   margin-left: 5px;
}
.navLink {
  color: black;
  text-decoration: none;
}
.navLink:hover {
  color: aqua;
}
.navLink .selected {

}
</style>
  