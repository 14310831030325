<template>
<div class="info">
  <div class="about">
    
  </div>
  <div class="experience">
    <div class="paragraph">
      I’m a 26 year old designer with a master’s degree in Industrial Design and Engineering from the Norwegian University
of Science and Technology. I enjoy a hands-on approach from modeling to making. I’m passionate about aesthetics, handcraft, and respect for context. As part of my design process, I like to keep an open mind and solve problems from various angles.
    </div>
    <div class="paragraph">
      My drive stems from a desire to create satisfying user experiences whether that be in the form of spatial atmosphere or in the clarity of state and use in a functional product.
    </div>
    <div class="paragraph">
      During my studies, I spent a substantial portion of my time in the industrial design workshop, often arriving early in the morning before faculty–planning processes and preparing material.
    </div>
    <div class="paragraph">
      As a student assistant, I led courses in laser cutting and CNC programming/operation, while overseeing 3D printer maintenance and booking. These domains felt very much
at home for me and gave me a chance to contribute my knowledge to the student body and faculty in a meaningful way.
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'AboutPage',
}
</script>

<style scoped>
.info {
  display: block;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}
.about {
  flex-grow: 1;
  text-align: left;
}
.experience {
  padding-top: 50px;
  padding-left: 50px;
  width: 500px;  /* flex-grow: 3; */
  text-align: left;
}

@media (max-width: 630px) {
  .experience {
    padding-left: 50px;
    padding-right: 70px;
    width: calc(100% - 170px); /* Change to responsive full width */
  }
}
.paragraph {
  line-height: 1.6;
  padding-bottom: 30px ;
}
</style>
